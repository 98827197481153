<template>
    <v-container fluid :class="affichagePortable ? 'pa-0' : ''">
		<v-row justify="center" class="ma-0">
            
            
            <!-- affichage portable -->
            <template v-if="affichagePortable">
                <v-col cols="12" xs="12" md="6" class="pa-0">
                    <v-row align="center" class="ma-0 mb-4 px-4 mt-4">
                        <v-btn fab outlined small @click="onClickMoisPrecedent" :loading="chargement_mois_precedent">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-slide-y-transition mode="out-in">
                            <div class="flex-grow-1 text-center primary--text text-h5 font-weight-light" :key="titre_mois" style="cursor: pointer" @click="onClickMoisCourant">{{ titre_mois }}</div>
                        </v-slide-y-transition>
                        <v-btn fab outlined small @click="onClickMoisSuivant" :loading="chargement_mois_suivant">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
                
                <!-- autorisation visualisation des participants -->
                <template v-if="acces_autorise(['acces participants manoeuvres'])">
                    <v-tabs fixed-tabs background-color="transparent" height="50" id="tabs_manoeuvres" v-model="onglet_manoeuvre">
                        <v-tab>Manoeuvres</v-tab>
                        <v-tab>Participants</v-tab>
                        
                        <!-- onglet manoeuvres -->
                        <v-tab-item>
                            <v-fade-transition mode="out-in">
                                <div v-if="manoeuvres_courantes.length > 0" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois : null">
                                    <v-list-item-group v-model="manoeuvres_selectionnees" multiple active-class="deep-purple--text">
                                        <template v-for="(manoeuvre, index) in liste_manoeuvres_choix">
                                            <v-list-item :key="manoeuvre.id" :value="manoeuvre.id" :disabled="desactivationChoix(manoeuvre)">
                                                <template v-slot:default="{ active, toggle }">
                                                    <v-list-item-action>
                                                        <v-checkbox v-model="active" color="deep-purple lighten-2"></v-checkbox>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-row class="ma-0" align="center">
                                                            <template v-if="manoeuvre.id != 'aucune'">
                                                                <v-col class="pa-0">
                                                                    <div>{{ formatDate(manoeuvre.date_debut) }}</div>
                                                                    <v-row class="ma-0" align="center">
                                                                        <div class="caption">{{ formatHeure(manoeuvre.date_debut) }}</div><v-icon size="15" class="mx-1">mdi-arrow-right-thick</v-icon><div class="caption">{{ formatHeure(manoeuvre.date_fin) }}</div>
                                                                    </v-row>
                                                                    <v-row class="ma-0">
                                                                        <v-chip v-for="equipe in manoeuvre.equipes" :key="equipe.id" label small class="mr-1 mt-1" :color="estMonEquipe(equipe) ? 'deep-purple lighten-4' : ''">{{equipe.nom}}</v-chip>
                                                                    </v-row>
                                                                </v-col>
                                                            </template>
                                                            <template v-else>
                                                                <div>{{manoeuvre.nom}}</div>
                                                            </template>
                                                        </v-row>
                                                    </v-list-item-content>
                                                    <v-list-item-action v-if="affichageCadenas(manoeuvre)">
                                                        <v-icon color="deep-purple lighten-3">mdi-lock</v-icon>
                                                    </v-list-item-action>
                                                </template>
                                            </v-list-item>
                                            <v-divider v-if="index + 1 < liste_manoeuvres_choix.length" :key="'divider_'+index"></v-divider>
                                        </template>
                                    </v-list-item-group>
                                    <v-fade-transition mode="out-in">
                                        <v-row class="ma-0 mt-6" justify="center" v-if="autorisationValiderChoix" :key="autorisationValiderChoix">
                                            <v-btn color="light-green" outlined @click="onClickValiderDate" :loading="chargement_validation">valider</v-btn>
                                        </v-row>
                                    </v-fade-transition>
                                </div>
                                <v-row v-else justify="center" class="ma-0 mt-12" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois+'no' : null">
                                    <div class="text-subtitle-1 font-italic font-weight-light">(aucune manoeuvre)</div>
                                </v-row>
                            </v-fade-transition>
                        </v-tab-item>

                        <!-- onglet participants -->
                        <v-tab-item>
                            <v-fade-transition mode="out-in">
                                <v-expansion-panels v-if="manoeuvres_courantes.length > 0" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois : null">
                                    <v-expansion-panel v-for="manoeuvre in manoeuvres_courantes" :key="manoeuvre.id">
                                        <v-expansion-panel-header hide-actions color="brown lighten-5">
                                            <v-row class="ma-0" align="center">
                                                <v-col class="pa-0">
                                                    <div>{{ formatDate(manoeuvre.date_debut) }}</div>
                                                    <v-row class="ma-0" align="center">
                                                        <div class="caption">{{ formatHeure(manoeuvre.date_debut) }}</div><v-icon size="15" class="mx-1">mdi-arrow-right-thick</v-icon><div class="caption">{{ formatHeure(manoeuvre.date_fin) }}</div>
                                                    </v-row>
                                                    <v-row class="ma-0">
                                                        <v-chip v-for="equipe in manoeuvre.equipes" :key="equipe.id" label small class="mr-1 mt-1" :color="estMonEquipe(equipe) ? 'deep-purple lighten-4' : ''">{{equipe.nom}}</v-chip>
                                                    </v-row>
                                                </v-col>
                                                <!-- <v-spacer/> -->
                                                <v-chip small color="brown lighten-4" text-color="brown darken-2">{{listeParticipantsActifs(manoeuvre).length}}</v-chip>
                                            </v-row>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="contenu_liste_participants" color="grey lighten-5">
                                            <v-list class="liste_participants py-0" v-if="listeMembresEquipes(manoeuvre).length > 0">
                                                <template v-for="(participant, index) in listeMembresEquipes(manoeuvre)">
                                                    <v-list-item :key="participant.id" :class="presenceManoeuvre(manoeuvre, participant).classe">
                                                        <v-list-item-avatar color="brown lighten-2" size="30">
                                                            <img v-if="participant.user.photo" :src="getPhoto(participant.user.photo)">
                                                            <span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(participant.user.prenom)+premiereLettreMaj(participant.user.nom)}}</span>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ participant.user.grade.nom + ' ' + participant.user.nom + ' ' +  participant.user.prenom}}</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-icon :color="presenceManoeuvre(manoeuvre, participant).icone_couleur">{{presenceManoeuvre(manoeuvre, participant).icone}}</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                    <v-divider :key="'divider'+index"></v-divider> <!-- permet de définir une clé différente de l'élément de liste sinon il y a conflit -->
                                                </template>
                                            </v-list>
                                            <v-row v-else class="zone_sans_participants pa-3 my-3" justify="center">
                                                <div class="text-subtitle-1 font-italic font-weight-light">(aucun participant)</div>
                                            </v-row>
                                            <v-row class="ma-0" justify="center" v-if="listeParticipantsHorsEquipe(manoeuvre).length > 0">
                                                <v-icon class="mt-4">mdi-account-multiple-plus</v-icon>
                                            </v-row>
                                            <v-list class="liste_participants py-0" v-if="listeParticipantsHorsEquipe(manoeuvre).length > 0">
                                                <template v-for="(participant, index) in listeParticipantsHorsEquipe(manoeuvre)">
                                                    <v-list-item :key="participant.id">
                                                        <v-list-item-avatar color="brown lighten-2" size="30">
                                                            <img v-if="participant.user.photo" :src="getPhoto(participant.user.photo)">
                                                            <span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(participant.user.prenom)+premiereLettreMaj(participant.user.nom)}}</span>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="text-left">{{ participant.user.grade.nom + ' ' + participant.user.nom + ' ' +  participant.user.prenom}}</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-icon color="light-green lighten-1">mdi-check-circle</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                    <v-divider :key="'divider'+index"></v-divider> <!-- permet de définir une clé différente de l'élément de liste sinon il y a conflit -->
                                                </template>
                                            </v-list>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <v-row v-else justify="center" class="ma-0 mt-6" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois+'no' : null">
                                    <div class="text-subtitle-1 font-italic font-weight-light">(aucune manoeuvre)</div>
                                </v-row>
                            </v-fade-transition>
                        </v-tab-item>
                    </v-tabs>
                </template>
                <template v-else>
                    <!-- affichage uniquement du choix de la manoeuvre -->
                    <v-fade-transition mode="out-in">
                                <v-row v-if="manoeuvres_courantes.length > 0" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois : null" class="ma-0">
                                    <v-list-item-group v-model="manoeuvres_selectionnees" multiple active-class="deep-purple--text" style="width: 100%">
                                        <template v-for="(manoeuvre, index) in liste_manoeuvres_choix">
                                            <v-list-item :key="manoeuvre.id" :value="manoeuvre.id" :disabled="desactivationChoix(manoeuvre)">
                                                <template v-slot:default="{ active, toggle }">
                                                    <v-list-item-action>
                                                        <v-checkbox v-model="active" color="deep-purple lighten-2"></v-checkbox>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-row class="ma-0" align="center">
                                                            <template v-if="manoeuvre.id != 'aucune'">
                                                                <v-col class="pa-0">
                                                                    <div>{{ formatDate(manoeuvre.date_debut) }}</div>
                                                                    <v-row class="ma-0" align="center">
                                                                        <div class="caption">{{ formatHeure(manoeuvre.date_debut) }}</div><v-icon size="15" class="mx-1">mdi-arrow-right-thick</v-icon><div class="caption">{{ formatHeure(manoeuvre.date_fin) }}</div>
                                                                    </v-row>
                                                                    <v-row class="ma-0">
                                                                        <v-chip v-for="equipe in manoeuvre.equipes" :key="equipe.id" label small class="mr-1 mt-1" :color="estMonEquipe(equipe) ? 'deep-purple lighten-4' : ''">{{equipe.nom}}</v-chip>
                                                                    </v-row>
                                                                </v-col>
                                                            </template>
                                                            <template v-else>
                                                                <div>{{manoeuvre.nom}}</div>
                                                            </template>
                                                        </v-row>
                                                    </v-list-item-content>
                                                    <v-list-item-action v-if="affichageCadenas(manoeuvre)">
                                                        <v-icon color="deep-purple lighten-3">mdi-lock</v-icon>
                                                    </v-list-item-action>
                                                </template>
                                            </v-list-item>
                                            <v-divider v-if="index + 1 < liste_manoeuvres_choix.length" :key="'divider_'+index"></v-divider>
                                        </template>
                                    </v-list-item-group>
                                    <v-row class="ma-0 mt-6" justify="center" v-if="autorisationValiderChoix">
                                        <v-btn color="light-green" outlined @click="onClickValiderDate" :loading="chargement_validation">valider</v-btn>
                                    </v-row>
                                    
                                </v-row>
                                <v-row v-else justify="center" class="ma-0 mt-12" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois+'no' : null">
                                    <div class="text-subtitle-1 font-italic font-weight-light">(aucune manoeuvre)</div>
                                </v-row>
                    </v-fade-transition>
                </template>
            </template>

            
            <!-- affichage PC  -->
            <template v-else>

                <v-col cols="12" xs="12" md="5"  class="pa-0">
                    <v-row align="center" class="ma-0 mb-4 px-4 mt-4">
                        <v-btn fab outlined small @click="onClickMoisPrecedent" :loading="chargement_mois_precedent">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-slide-y-transition mode="out-in">
                            <div class="flex-grow-1 text-center primary--text text-h5 font-weight-light" :key="titre_mois" style="cursor: pointer" @click="onClickMoisCourant">{{ titre_mois }}</div>
                        </v-slide-y-transition>
                        <v-btn fab outlined small @click="onClickMoisSuivant" :loading="chargement_mois_suivant">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
                    <v-fade-transition mode="out-in">
                        <v-row class="ma-0 mt-8" v-if="manoeuvres_courantes.length > 0" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois : null">
                            <v-fade-transition mode="out-in">
                                <v-row class="ma-0" v-if="manoeuvres_courantes.length > 0" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois : null">
                                    <v-list-item-group v-model="manoeuvres_selectionnees" multiple active-class="deep-purple--text" style="width: 100%">
                                        <template v-for="(manoeuvre, index) in liste_manoeuvres_choix">
                                            <v-list-item :key="manoeuvre.id" :value="manoeuvre.id" :disabled="desactivationChoix(manoeuvre)">
                                                <template v-slot:default="{ active, toggle }">
                                                    <v-list-item-action>
                                                        <v-checkbox v-model="active" color="deep-purple lighten-2"></v-checkbox>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-row class="ma-0" align="center">
                                                            <template v-if="manoeuvre.id != 'aucune'">
                                                                <v-col class="pa-0">
                                                                    <div>{{ formatDate(manoeuvre.date_debut) }}</div>
                                                                    <v-row class="ma-0" align="center">
                                                                        <div class="caption">{{ formatHeure(manoeuvre.date_debut) }}</div><v-icon size="15" class="mx-1">mdi-arrow-right-thick</v-icon><div class="caption">{{ formatHeure(manoeuvre.date_fin) }}</div>
                                                                    </v-row>
                                                                    <v-row class="ma-0">
                                                                        <v-chip v-for="equipe in manoeuvre.equipes" :key="equipe.id" label small class="mr-1 mt-1" :color="estMonEquipe(equipe) ? 'deep-purple lighten-4' : ''">{{equipe.nom}}</v-chip>
                                                                    </v-row>
                                                                </v-col>
                                                            </template>
                                                            <template v-else>
                                                                <div>{{manoeuvre.nom}}</div>
                                                            </template>
                                                        </v-row>
                                                    </v-list-item-content>
                                                    <v-list-item-action v-if="affichageCadenas(manoeuvre)">
                                                        <v-icon color="deep-purple lighten-3">mdi-lock</v-icon>
                                                    </v-list-item-action>
                                                </template>
                                            </v-list-item>
                                            <v-divider v-if="index + 1 < liste_manoeuvres_choix.length" :key="'divider_'+index"></v-divider>
                                        </template>
                                    </v-list-item-group>
                                    <v-fade-transition mode="out-in">
                                        <v-row class="ma-0 mt-6" justify="center" v-if="autorisationValiderChoix" :key="autorisationValiderChoix">
                                            <v-btn color="light-green" outlined @click="onClickValiderDate" :loading="chargement_validation">valider</v-btn>
                                        </v-row>
                                    </v-fade-transition>
                                    
                                </v-row>
                                <v-row v-else justify="center" class="ma-0 mt-12" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois+'no' : null">
                                    <div class="text-subtitle-1 font-italic font-weight-light">(aucune manoeuvre)</div>
                                </v-row>
                            </v-fade-transition>
                        </v-row>
                        <v-row v-else justify="center" class="ma-0 mt-12" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois+'no' : null">
                            <div class="text-subtitle-1 font-italic font-weight-light">(aucune manoeuvre)</div>
                        </v-row>
                    </v-fade-transition>
                </v-col>
                
                <!-- autorisation visualisation des participants -->
                <template v-if="acces_autorise(['acces participants manoeuvres'])">
                    
                    <v-col cols="12" xs="12" md="5" class="pa-0 pl-12 offset-md-1">
                        <v-row class="ma-0 mt-8" justify="center">
                            <v-col class="pa-0" align="center">
                                <div class="mb-6 text-h6 font-weight-light">Participants</div>
                                <v-fade-transition mode="out-in">
                                    <v-expansion-panels id="expansion_liste_participants_pc" flat v-if="manoeuvres_courantes.length > 0" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois : null">
                                        <v-expansion-panel v-for="manoeuvre in manoeuvres_courantes" :key="manoeuvre.id" class="participants_manoeuvre_pc">
                                            <v-expansion-panel-header hide-actions color="brown lighten-5">
                                                <v-row class="ma-0" align="center">
                                                    <v-col class="pa-0">
                                                        <div>{{ formatDate(manoeuvre.date_debut) }}</div>
                                                        <v-row class="ma-0" align="center">
                                                            <div class="caption">{{ formatHeure(manoeuvre.date_debut) }}</div><v-icon size="15" class="mx-1">mdi-arrow-right-thick</v-icon><div class="caption">{{ formatHeure(manoeuvre.date_fin) }}</div>
                                                        </v-row>
                                                        <v-row class="ma-0">
                                                            <v-chip v-for="equipe in manoeuvre.equipes" :key="equipe.id" label small class="mr-1 mt-1" :color="estMonEquipe(equipe) ? 'deep-purple lighten-4' : ''">{{equipe.nom}}</v-chip>
                                                        </v-row>
                                                    </v-col>
                                                    <!-- <v-spacer/> -->
                                                    <v-chip small color="brown lighten-4" text-color="brown darken-2">{{listeParticipantsActifs(manoeuvre).length}}</v-chip>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content class="contenu_liste_participants" color="grey lighten-5">
                                                <v-list class="liste_participants py-0" v-if="listeMembresEquipes(manoeuvre).length > 0">
                                                    <template v-for="(participant, index) in listeMembresEquipes(manoeuvre)">
                                                        <v-list-item :key="participant.id" :class="presenceManoeuvre(manoeuvre, participant).classe">
                                                            <v-list-item-avatar color="brown lighten-2" size="30">
                                                                <img v-if="participant.user.photo" :src="getPhoto(participant.user.photo)">
                                                                <span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(participant.user.prenom)+premiereLettreMaj(participant.user.nom)}}</span>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="text-left">{{ participant.user.grade.nom + ' ' + participant.user.nom + ' ' +  participant.user.prenom}}</v-list-item-title>
                                                            </v-list-item-content>
                                                            <v-list-item-icon>
                                                                <v-icon :color="presenceManoeuvre(manoeuvre, participant).icone_couleur">{{presenceManoeuvre(manoeuvre, participant).icone}}</v-icon>
                                                            </v-list-item-icon>
                                                        </v-list-item>
                                                        <v-divider :key="'divider'+index"></v-divider> <!-- permet de définir une clé différente de l'élément de liste sinon il y a conflit -->
                                                    </template>
                                                </v-list>
                                                <v-row v-else class="zone_sans_participants pa-3 my-3" justify="center">
                                                    <div class="text-subtitle-1 font-italic font-weight-light">(aucun participant)</div>
                                                </v-row>
                                                <v-row class="ma-0" justify="center" v-if="listeParticipantsHorsEquipe(manoeuvre).length > 0">
                                                    <v-icon class="mt-4">mdi-account-multiple-plus</v-icon>
                                                </v-row>
                                                <v-list class="liste_participants py-0" v-if="listeParticipantsHorsEquipe(manoeuvre).length > 0">
                                                    <template v-for="(participant, index) in listeParticipantsHorsEquipe(manoeuvre)">
                                                        <v-list-item :key="participant.id">
                                                            <v-list-item-avatar color="brown lighten-2" size="30">
                                                                <img v-if="participant.user.photo" :src="getPhoto(participant.user.photo)">
                                                                <span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(participant.user.prenom)+premiereLettreMaj(participant.user.nom)}}</span>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="text-left">{{ participant.user.grade.nom + ' ' + participant.user.nom + ' ' +  participant.user.prenom}}</v-list-item-title>
                                                            </v-list-item-content>
                                                            <v-list-item-icon>
                                                                <v-icon color="light-green lighten-1">mdi-check-circle</v-icon>
                                                            </v-list-item-icon>
                                                        </v-list-item>
                                                        <v-divider :key="'divider'+index"></v-divider> <!-- permet de définir une clé différente de l'élément de liste sinon il y a conflit -->
                                                    </template>
                                                </v-list>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    <v-row v-else justify="center" class="ma-0 mt-12" :key="manoeuvres_courantes.length > 0 ? manoeuvres_courantes[0].mois+'no' : null">
                                        <div class="text-subtitle-1 font-italic font-weight-light">(aucune manoeuvre)</div>
                                    </v-row>
                                </v-fade-transition>
                            </v-col>
                        </v-row>
                    </v-col>
                </template>
            </template>
		</v-row>
	</v-container>
</template>

<script>
    import moment from 'moment'
    moment.locale('fr')
    import axios from 'axios'
    import { cloneDeep } from 'lodash'
    import { required } from 'vuelidate/lib/validators'
    export default {
        created() {
            this.$store.commit('majTitrePage', 'Présence manoeuvres')
        },
        mounted() {
            this.date_debut = moment().startOf('month').format('YYYY-MM-DD')
            this.date_fin = moment().endOf('month').format('YYYY-MM-DD')
            this.fetchManoeuvres()
        },
        data() {
			return {
                chargement_confirmation: false,
                chargement_mois_precedent: false,
                chargement_mois_suivant: false,
                chargement_validation: false,
                date_debut: null,
                date_fin: null,
                dialogue_confirmation: {
                    titre: '',
                    texte: '',
                    action: ''
                },
                manoeuvres_courantes: [],
                manoeuvres_selectionnees: [],
                onglet_manoeuvre: null,
                openDialogueConfirmation: false,
            }
		},
		computed: {
            affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
            },
            autorisationValiderChoix() {
                //  on masque le bouton lors du chargement pour eviter un bug de transition
                if(this.chargement_mois_precedent || this.chargement_mois_suivant) return false

                //  détermination de la date de la derniere manoeuvre
                let date_fin_derniere_manoeuvre = this.date_debut
                for (const manoeuvre of this.manoeuvres_courantes) {
                    if(moment(manoeuvre.date_fin).isAfter(moment(date_fin_derniere_manoeuvre))) {
                        date_fin_derniere_manoeuvre = manoeuvre.date_fin
                    }
                }

                return this.manoeuvres_selectionnees.length > 0 && moment().isBefore(moment(date_fin_derniere_manoeuvre))
            },
            centre_courant() {
				return this.$store.getters.membreCourant.centre
            },
            estMoisPasse() {
                //  renvoie true si le mois est passe
                return moment(this.date_fin).isBefore(moment())
            },
            liste_manoeuvres_choix() {
                //  retourne la liste des manoeuvres courantes avec l'option "aucune" si au moins 1 manouevre
                const liste = cloneDeep(this.manoeuvres_courantes)
          
                if(liste.length > 0) {
                    
                    liste.push({
                        id: 'aucune',
                        nom: 'Aucune manoeuvre'
                    })
                }
                return liste
            },
            titre_mois() {
                return moment(this.date_debut).format('MMMM YYYY')
            }
		},
		methods: {
            acces_autorise(permissions_requises, acces_admin = true) {
                // on vérifie si l'utilisateur a les droits
                //  "permissions_requises" est un tableau de permissions.
                //  return true si l'utilisateur possede l'une des permissions du tableau

                // "acces_admin"  donne ou non l'autorisation à l'admin
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin && acces_admin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
            affichageCadenas(manoeuvre) {
                //  on n'affiche le cadenas que si le choix est verrouillé et que le mois n'est pas passé
                return this.desactivationChoix(manoeuvre) && !moment(this.manoeuvres_courantes[0].date_debut).endOf('month').isBefore(moment())
            },
            annuleConfirmation() {
                this.dialogue_confirmation.titre = ''
                this.dialogue_confirmation.texte = ''
                this.dialogue_confirmation.action = ''
                this.openDialogueConfirmation = false
            },
            desactivationChoix(manoeuvre) {
                //  renvoie true si le choix de la manoeuvre ne peux plus être changé
                //  on désactive le chaix 'aucun' si un choix désactivé est déjà fait
                if(manoeuvre.id == 'aucune') {
                    let cas_trouve = false
                    for (const manoeuvre of this.manoeuvres_courantes) {
                        if(this.desactivationChoix(manoeuvre) && this.manoeuvres_selectionnees.includes(manoeuvre.id)) {
                            cas_trouve = true
                            break
                        }
                    }
                    //  si le mois est passé on désactive aussi (on se base sur la date de la 1ere manoeuvre)
                    if(moment().isAfter(moment(this.manoeuvres_courantes[0].date_debut).endOf('month'))) {
                        cas_trouve = true
                    }
                    return cas_trouve
                }

                else {
                    //  on désactive si la date est passée
                    return moment().isAfter(manoeuvre.date_fin)
                }
                
            },
            estMonEquipe(equipe) {
                //  retourne true si l'équipe est celle de l'utilisateur courant
                return equipe.id == this.$store.getters.membreCourant.id_equipe
            },
            fetchManoeuvres() {
                //	on active les 2 loading si aucun ne l'est (cas du chargement initial)
				if(!this.chargement_mois_precedent && !this.chargement_mois_suivant) {
					this.chargement_mois_suivant = true
					this.chargement_mois_precedent = true
					
                }
                axios.post('api/manoeuvres/manoeuvre_centre', { 
                        id_centre: this.$store.getters.membreCourant.centre.id ,
                        annee: moment(this.date_debut).format('YYYY'), 
                        mois: moment(this.date_debut).format('MM') ,
                        id_membre: this.$store.getters.membreCourant.id,
                    })
                    .then( (reponse) => {
						this.manoeuvres_courantes = reponse.data.manoeuvres
						this.initialiserChoix()
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors du chargement des manoeuvres');
                    })
                    .then(() => {
						this.chargement_mois_suivant = false
						this.chargement_mois_precedent = false
                    })
            },
            formatDate(date) {
                if(!date) return ''
                return moment(date).format('dddd DD/MM/YYYY')
            },
            formatHeure(date) {
                //  ne retourne que la partie heure de la date
                if(!date) return ''
                return moment(date).format('H:mm')
            },
            getPhoto(chemin){
                return "/storage/"+chemin;
            },
            initialiserChoix() {
                // sélectionne la ou les manoeuvres enregistrées en bdd
                this.manoeuvres_selectionnees = []
                if(this.manoeuvres_courantes.length > 0) {
                    let compteur_absence = 0
                    for (const manoeuvre of this.manoeuvres_courantes) {
                        
                        for (const participant of manoeuvre.participants) {
                            
                            //  si l'utilisateur participe
                            if(participant.id == this.$store.getters.membreCourant.id) {
                                if(participant.pivot.presence_confirmee == 1) {
                                    this.manoeuvres_selectionnees.push(manoeuvre.id)
                                }
                                else {
                                    compteur_absence += 1
                                }
                                break
                            }
                        }
                    }
                    if(compteur_absence >= this.manoeuvres_courantes.length) {
                        this.manoeuvres_selectionnees = ['aucune']
                    }

                }
            },
            listeMembresEquipes(manoeuvre) {
                //  retourne la liste des membres des équipes supposés participer à la manoeuvre
                let liste = []
                for (const equipe of manoeuvre.equipes) {
                    for (const membre of equipe.membres) {
                        liste.push(membre)
                    }
                }

                //  on trie la liste par NOM/PRENOM
                return liste.sort((a,b) => {
					if(a.user.nom.toLowerCase() < b.user.nom.toLowerCase()) {
						return -1
					}
					else if(a.user.nom.toLowerCase() > b.user.nom.toLowerCase()) {
						return 1
					}

					//	ici on a le même nom donc on tri par prénom
					if(a.user.prenom.toLowerCase() < b.user.prenom.toLowerCase()) {
						return -1
					}
					else if(a.user.prenom.toLowerCase() > b.user.prenom.toLowerCase()) {
						return 1
					}
                    return 0
                })
                
            },
            listeParticipantsActifs(manoeuvre) {
                const liste = manoeuvre.participants.filter(participant => participant.pivot.presence_confirmee == 1)

                //  on trie la liste par NOM/PRENOM
                return liste.sort((a,b) => {
					if(a.user.nom.toLowerCase() < b.user.nom.toLowerCase()) {
						return -1
					}
					else if(a.user.nom.toLowerCase() > b.user.nom.toLowerCase()) {
						return 1
					}

					//	ici on a le même nom donc on tri par prénom
					if(a.user.prenom.toLowerCase() < b.user.prenom.toLowerCase()) {
						return -1
					}
					else if(a.user.prenom.toLowerCase() > b.user.prenom.toLowerCase()) {
						return 1
					}
                    return 0
                })
            },
            listeParticipantsHorsEquipe(manoeuvre) {
                //  retourne la liste des participants à la manoeuvre qui ne sont pas dans les équipes prévues à cette manoeuvre

                //  récupération des id des équipes participantes
                let id_equipes = []
                for (const equipe of manoeuvre.equipes) {
                    id_equipes.push(equipe.id)
                }

                const  liste = manoeuvre.participants.filter(participant => !id_equipes.includes(participant.id_equipe) && participant.pivot.presence_confirmee == 1)

                //  on trie la liste par NOM/PRENOM
                return liste.sort((a,b) => {
					if(a.user.nom.toLowerCase() < b.user.nom.toLowerCase()) {
						return -1
					}
					else if(a.user.nom.toLowerCase() > b.user.nom.toLowerCase()) {
						return 1
					}

					//	ici on a le même nom donc on tri par prénom
					if(a.user.prenom.toLowerCase() < b.user.prenom.toLowerCase()) {
						return -1
					}
					else if(a.user.prenom.toLowerCase() > b.user.prenom.toLowerCase()) {
						return 1
					}
                    return 0
                })
				
            },
            onClickMoisCourant() {
                this.date_debut = moment().startOf('month').format('YYYY-MM-DD')
                this.date_fin = moment().endOf('month').format('YYYY-MM-DD')
                this.fetchManoeuvres()
            },
            onClickMoisPrecedent() {
                this.chargement_mois_precedent = true
                this.date_debut = moment(this.date_debut).subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
                this.date_fin = moment(this.date_debut).endOf('month').format('YYYY-MM-DD')
                this.fetchManoeuvres()
            },
            onClickMoisSuivant() {
                this.chargement_mois_suivant = true
                this.date_debut = moment(this.date_debut).add(1, 'month').startOf('month').format('YYYY-MM-DD')
                this.date_fin = moment(this.date_debut).endOf('month').format('YYYY-MM-DD')
                this.fetchManoeuvres()
            },
            onClickValiderDate() {
                this.chargement_validation = true

                //  on récupère la liste des ids de toutes les manoeuvres du mois
                let liste_ids = []
                for (const manoeuvre of this.manoeuvres_courantes) {
                    liste_ids.push(manoeuvre.id)
                }
                axios.post('api/manoeuvres/valider_presence', { 
                        id_membre: this.$store.getters.membreCourant.id, 
                        liste_ids_manoeuvres_mois: liste_ids,
                        liste_ids_selectionnees: this.manoeuvres_selectionnees
                    })
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.fetchManoeuvres()    
                    })
                    .catch((error) => {
                        this.snackbar('error', 'Erreur lors de la validation de la présence');
                    })
                    .then(() => {
                        this.chargement_validation = false
                    })
            },
            premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
            },
            presenceManoeuvre(manoeuvre, membre) {
                // retourne la classe/icone à appliquer en fonction de la présence ou non du SP à la manoeuvre

                //  distinction à faire entre: présent/sur une autre équipe/non positionné/aucune manoeuvre

                let resultat = {
                    classe: '',
                    icone: '',
                    icone_couleur: '',
                    equipe: []  // liste des noms d'équipe externes où ira le SP
                }

                //  on vérifie si le membre est dans la liste des participants et s'il a confirmé sa présence
                const index_participant = manoeuvre.participants.findIndex(participant => participant.id_user == membre.id_user)
                if(index_participant > -1 && manoeuvre.participants[index_participant].pivot.presence_confirmee == 1) {
                    resultat.classe = ''
                    resultat.icone = 'mdi-check-circle'
                    resultat.icone_couleur = 'light-green lighten-1'
                }
                
                else {
                    resultat.classe = 'membreAbsent'
                    
                    let compteur_absence = 0
                    let liste_equipes = []
                    
                    //  on parcourt chaque manoeuvre du mois
                    for (const la_manoeuvre of this.manoeuvres_courantes) {
                        const index_participant = la_manoeuvre.participants.findIndex(participant => participant.id_user == membre.id_user)
                        if(index_participant > -1 && la_manoeuvre.participants[index_participant].pivot.presence_confirmee == 0) {
                            compteur_absence += 1
                        }
                        if(index_participant > -1 && la_manoeuvre.participants[index_participant].pivot.presence_confirmee == 1) {
                            liste_equipes.push(la_manoeuvre.date_debut)
                        }

                    }

                    //  si autant de non presence confirmee que de manoeuvre dans le mois: aucune manoeuvre
                    if(compteur_absence == this.manoeuvres_courantes.length) {
                        resultat.icone = 'mdi-close-circle'
                        resultat.icone_couleur = 'red lighten-1'
                    }
                    else if(liste_equipes.length > 0) {
                        resultat.icone = 'mdi-location-exit'
                        resultat.icone_couleur = 'orange lighten-1'
                    }
                    else {
                        resultat.icone = 'mdi-help-circle-outline'
                        resultat.icone_couleur = 'blue lighten-1'
                    }
                    
                }

                return resultat
            },   
            snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
            },
        },
        validations: {
            
        },
        watch: {
            manoeuvres_selectionnees: {
                handler(nouvelle_valeur, ancienne_valeur) {
                    const contient_aucune_ancien = ancienne_valeur.includes('aucune')
                    const contient_aucune_nouveau = nouvelle_valeur.includes('aucune')

                    if(!contient_aucune_ancien && contient_aucune_nouveau) {
                        this.manoeuvres_selectionnees = ['aucune']
                    }
                    
                    else if(contient_aucune_ancien && ancienne_valeur.length == 1 && nouvelle_valeur.length > 1) {
                        //  on retire 'ancien' de la liste
                        const index = this.manoeuvres_selectionnees.findIndex( manoeuvre => manoeuvre == 'aucune')
                        if(index >= 0) {
                            this.manoeuvres_selectionnees.splice(index, 1)
                        }
                    }
            
                }
            },
            centre_courant() {
                
                //  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces manoeuvres')) {
                    this.$router.push('/')
                }
                else {
                    this.fetchManoeuvres()
                    // this.$store.dispatch('getManoeuvresMembre', this.$store.getters.membreCourant.centre.id).then(() => {
                    //     this.onglet_manoeuvre = 0       // on se remet sur l e1er onglet
                    // })
                }
            }
        }
  	}
</script>

<style scoped>
/* #liste_participants {
    overflow-y: auto;
    max-height: calc(100vh - 260px);
}

.row_date_manoeuvre {
    background-color: #EDE7F6;
} */

#expansion_liste_participants_pc {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    overflow-x: hidden;
}

.liste_participants .zone_sans_participants {
    max-height: calc(100vh - 260px);
    min-height: 290px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
}

.liste_participants {
    background-color: transparent;
}

.membreAbsent{
    background-color:#E0E0E0;
}
</style>



<style>
#tabs_manoeuvres .v-tabs-items {
    background-color: transparent !important;
}

.chip_nom_equipe {
    height: 80px !important;
}

.contenu_liste_participants .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    
}

.participants_manoeuvre_pc {
    border-bottom: 1px solid #E0E0E0 !important;
}


</style>